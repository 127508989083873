body {
  position: relative;
  margin: auto;
  max-width: 1200px;
  width:100%;
  /*display: flex;*/
  justify-content: space-around;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  font-family: Roboto sans-serif;
  /*display: flex;*/
  /*margin: auto;*/
  background: #121212;
  color:#fff;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-common{
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255,255,255,0.15);
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: white;
}

.select-common{
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255,255,255,0.15);
  border:1px solid rgba(255, 255, 255, 0.12);
  color:white;
}

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.69);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

body::-webkit-scrollbar {
  display: none;
}

.hide-scrolls::-webkit-scrollbar{
  display: none;
}

.hide-scrolls{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.control-panel{
  display: flex;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  position: fixed;
  top: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #121212;
  z-index: 2;
}

.control-panel p{
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 22px;
}

.active-href{
  color:white;
  cursor: pointer;
  text-decoration: underline;
}

.general-information{
  max-width: 1200px;
  background: #121212;
  padding: 10px;
  margin-left: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border:1px solid rgba(255, 255, 255, 0.12);
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.general-information div{
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  min-width: 300px;
}

.general-information div h4{
  margin-top: 5px;
  margin-bottom: 5px;
}
/*style={{marginTop:10, width:"100%"}}*/
.input-field-general{
  margin-top: 10px;
  width: 100%;
}

.input-field-general label{
  font-size: 10px;
}

.input-field-general input{
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255,255,255,0.15);
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: white;
}




